* {
  box-sizing: border-box;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  min-height: 100%;
  color: white;
  margin: 0px;
}

body {
  min-height: 100%;
  background-color: #070c10;

  margin: 0px;
  font-family: "Segoe UI", Tahoma, Verdana, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  text-align: center;
}
#root {
  min-height: 100%;
  max-width: 1800px;
  margin: 0px auto;
}

.top {
  display: flex;
  justify-content: space-between;
  place-items: center;
  padding: 5px;
  padding-left: 25px;
  padding-right: 25px;
  margin: 0px auto;
  width: 80%;
}

.title-container {
  position: relative;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  box-shadow: rgba(255, 255, 255, 0.25) 0px 14px 28px,
    rgba(255, 255, 255, 0.22) 0px 10px 10px;
  border-radius: 20px;
  overflow: hidden;
}

.title-image {
  width: 100%;
  border-radius: 20px;
  display: block;
}

.logo-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.logo-container img {
  width: 35%;
}

.openinghours {
  padding: 10px;
  font-size: 14px;
}

.menue {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
  width: 80%;

  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
}
.menue a {
  color: #d5b03a;
  text-decoration: underline;
}

.main-3-images {
  display: flex;
  flex-direction: row;
  justify-content: center;

  gap: 10px;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 25px;
}

.img-container-text {
  width: 33%;

  border-radius: 15px;
  padding: 10px;
}

.img-container-text img {
  width: 100%;
}

.quote-element {
  background-color: rgba(73 119 138 / 0.2);
  color: #cbd2dd;
  max-width: 80%;
  border-radius: 15px;
  padding: 15px;
  margin: 35px auto;
  box-shadow: rgba(255, 255, 255, 0.22) 0px 10px 25px,
    rgba(255, 255, 255, 0.22) 0px 5px 5px;
}

.rdli {
  width: 350px;
  margin: 25px;
}

.img-container-text p {
  text-align: left;
}
.img-container-text img {
  border-radius: 15px;
}

.text {
  max-width: 80%;
  margin: 35px auto;
}

.small-image-container {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.small-image-container img {
  width: 250px;
}

.footer {
  display: flex;
  justify-content: space-evenly;
  gap: 25px;
  padding: 25px;
  border-top: 1px solid #d5b03a;
  margin-top: 35px;
}
.footer-text {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: white;
  gap: 5px;
}

.footer-text a {
  color: white;
}

.social {
  display: flex;
  gap: 15px;
  justify-content: center;
  place-items: center;
}

.social-top {
  display: flex;
  gap: 10px;
  justify-content: center;
  place-items: center;
}

.social-top img {
  width: 20px;
}
.social img {
  width: 30px;
}

.height100 {
  min-height: 100vh !important;
}
.App {
  min-height: 100%;
}

.produkt-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
}

.produktkarte {
  width: 300px;
  border-radius: 15px;
  padding: 15px;
  background-color: rgba(73 119 138 / 0.2);
  color: #cbd2dd;
}

.produktkarte h2 {
  margin: 0px;
}
.produktkarte h3 {
  margin: 0px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.produktkarte img {
  width: 100%;
  border-radius: 15px;
}

.smaller-picture {
  margin-top: 25px;
  width: 80%;
  border-radius: 15px;
}
.cookiebanner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(7 12 16 / 0.9);
  color: white;
  padding: 10px;
  text-align: center;
  z-index: 100;
  border-top: 1px solid white;
  font-size: 12px;
}

.cookiebanner input {
  cursor: pointer;
}

.cookie-links a {
  color: #d5b03a;
  text-decoration: underline;
}

.sc {
  color: #cbd2dd;
}

.top a {
  color: white;
}
.white {
  color: white;
}

.cookiebanner p {
  margin-top: 0px;
  margin-bottom: 8px;
}
.banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: url("https://www.aquazen.de/pictures/ausverkauf.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  backdrop-filter: blur(5px);
  animation-delay: 3s;
  animation-name: fadeOutAnimation;
  animation-duration: 2s; /* Dauer der Animation */
  animation-fill-mode: forwards;
}

video {
  max-height: 80vh;
  border-radius: 15px;
  margin: 25px auto;
  display: block;
}

.video-container {
  position: relative;
}

.date {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-weight: 700;
}

#back {
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: #d5b03a;
  display: flex;
  justify-content: center;
  align-items: center;
  place-items: center;
}

#back svg {
  transform: rotate(270deg);
}

.hide {
  display: none !important;
}

.reviews-container {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin: 25px auto;
  max-width: 80%;
  justify-content: center;
  overflow: hidden;
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
}

.review {
  width: 250px;
  height: 200px;
  padding: 15px;
  border-radius: 15px;
  background-color: rgba(73 119 138 / 0.2);
  color: #cbd2dd;
  overflow: hidden;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0px !important;
  box-shadow: rgba(255, 255, 255, 0.4) 1px 1px 5px;
}

.reviewer-name {
  font-weight: 700;
  font-size: 14px;
  margin: 0px !important;
}

.reviewer-review {
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Anzahl der anzuzeigenden Zeilen */
  -webkit-box-orient: vertical;
  font-size: 16px;
  color: #d5b03a;
  overflow: hidden;
  text-overflow: ellipsis;
  height: auto; /* Oder eine feste Höhe, die mindestens der Höhe der drei Zeilen entspricht */
  white-space: normal; /* Erlaubt Zeilenumbrüche */
  color: #cbd2dd;
}

.star {
  width: 18px;
}

.stars-container {
  display: flex;
  gap: 5px;
  justify-content: center;
}

.arrow-left {
  position: absolute;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
}

.arrow-left,
.arrow-right {
  width: 35px;
  height: 35px;

  background-color: #d5b03a;
  padding: 10px;
  border-radius: 100%;
  color: white;
  cursor: pointer;
  z-index: 100;

  display: grid;
  place-items: center;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.5);
}

.arrow-right {
  position: absolute;
  top: 50%;
  right: 2px;
  transform: translateY(-50%);
}

.arrow-left svg,
.arrow-right svg {
  width: 15px;
  height: 15px;
}

.arrow-left svg {
  transform: rotate(180deg);
}

.futter {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 25px auto;
  max-width: 80%;
  justify-content: center;
}

ul {
  max-width: fit-content;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
}
li {
  text-align: left;
}

.container-flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
}

.futter-bild {
  width: 80%;
  max-width: 300px;
}

.futter-bild-2 {
  width: 80%;
  max-width: 500px;
  margin: 0px auto;
}

.quote-pictures {
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  gap: 55px;
  justify-content: center;
}

.mw {
  max-width: min(900px, 80%);
}

.quote-pictures img {
  max-width: 100px;
}

.nochda {
  max-width: 90%;
  margin: 0px auto;
}

.yarl__navigation_next {
  right: 0px;
}

.yarl__navigation_prev {
  left: 0px;
}

.yarl__icon {
  width: 50px !important;
  height: 50px !important;
}

h1,
h2,
h3 {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.click {
  font-size: 18px;
  color: #cbd2dd;
  margin-top: -10px;
  margin-bottom: 25px;
}

.calc {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 25px auto;
  max-width: 80%;
  justify-content: center;
}
.calctop {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
}

.flexrow {
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: center;
  flex-wrap: wrap;
}

.erg {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: rgba(73 119 138 / 0.2);
  color: #cbd2dd;
  max-width: 80%;
  border-radius: 15px;
  padding: 30px;
  margin: 35px auto;
  font-size: 24px;
  font-weight: 700;
}

.choosecalc {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin: 25px auto;
  max-width: 80%;
  justify-content: center;
  flex-wrap: wrap;
}

.choosecalc div {
  padding: 15px;
  border-radius: 15px;
  background-color: rgba(73 119 138 / 0.2);
  color: #cbd2dd;
  cursor: pointer;
}

.selected {
  background-color: #d5b03a !important;
  color: #000 !important;
  font-weight: 700;
}

@keyframes fadeOutAnimation {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@media screen and (min-width: 1700px) {
  .openinghours {
    font-size: 18px;
  }
  .social-top img {
    width: 25px;
  }
  p {
    font-size: 18px;
  }
}
@media screen and (max-width: 1000px) {
  .small-image-container img {
    width: 200px;
  }
}

@media screen and (max-width: 800px) {
  .small-image-container {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 700px) {
  .title-container {
    width: 95%;
  }
  .logo-container img {
    width: 35%;
  }

  h1 {
    font-size: 20px;
  }
}

@media screen and (max-width: 600px) {
  .main-3-images {
    flex-wrap: wrap;
  }

  .img-container-text {
    flex-basis: 80%;
  }

  .container-flex {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    gap: 15px;
    place-items: center;
  }

  ul {
    max-width: fit-content;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    margin-top: -20px;
  }

  .futter-bild {
    width: 90%;
    max-width: 300px;
  }

  .futter-bild-2 {
    width: 90%;
    max-width: 500px;
    margin: 0px auto;
  }

  .quote-pictures {
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    gap: 35px;
    justify-content: center;
  }

  .quote-pictures img {
    max-width: 80px;
  }

  .calcinputfield {
    font-size: 18px !important;
    width: 80px !important;
  }
}

@media screen and (max-width: 580px) {
  .click {
    font-size: 14px;
  }
  .top {
    margin-top: 15px;
    flex-direction: column-reverse;
  }
  .footer {
    flex-direction: column;
  }
}

@media screen and (max-width: 500px) {
  .title-container {
    margin-bottom: 15px;
  }
  .menue {
    margin-top: 0px;
  }

  video {
    max-height: 80vh;
    max-width: 90%;
  }

  #back {
    width: 25px;
    height: 25px;
  }

  #back svg {
    width: 15px;
    height: 15px;
  }

  .quote-pictures {
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    gap: 30px;
    justify-content: center;
  }

  .quote-pictures img {
    max-width: 70px;
  }
}

@media screen and (max-width: 400px) {
  h1 {
    font-size: 16px;
  }

  .main-3-images {
    margin-left: 15px;
    margin-right: 15px;
  }
  .img-container-text {
    flex-basis: 90%;
  }

  .small-image-container img {
    width: 90%;
  }
  .rdli {
    width: 80%;
  }
  .w80 {
    width: 80%;
    margin: 0px auto;
  }

  .cookiebanner {
    font-size: 10px;
  }

  .arrow-left {
    top: 50%;
    left: 0px;
  }
  .arrow-left,
  .arrow-right {
    width: 35px;
    height: 35px;
  }
  .arrow-right {
    top: 50%;
    right: 0px;
  }

  .arrow-left svg,
  .arrow-right svg {
    width: 15px;
    height: 15px;
  }

  .quote-pictures {
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    gap: 25px;
    justify-content: center;
  }

  .quote-pictures img {
    max-width: 60px;
  }
}

@media screen and (max-width: 350px) {
  .menue {
    gap: 10px;
    flex-wrap: wrap;
    width: 95%;
  }
  h1 {
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .arrow-left {
    position: absolute;
    top: 90%;
  }
  .arrow-left,
  .arrow-right {
    width: 35px;
    height: 35px;
  }
  .arrow-right {
    position: absolute;
    top: 90%;
  }

  .arrow-left svg,
  .arrow-right svg {
    width: 15px;
    height: 15px;
  }
}
